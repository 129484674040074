import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CartItem, Product } from "../../types";
import Toast from "../Toast/Toast";
import Cart from "./Cart";
import ProductComponent from "./ProductComponent";
import "./Shop.css";
import { fetchProductsStatic } from "./service/productService";

const Shop: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [cart, setCart] = useState<CartItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCart, setShowCart] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsData = await fetchProductsStatic();
        setProducts(productsData);
      } catch (error) {
        console.error(error);
        console.log("Fehler beim Laden der Produkte.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const savedCart = localStorage.getItem("cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, []);

  const handleAddToCart = (product: Product, selectedSize: string) => {
    const newCartItem: CartItem = {
      id: uuidv4(),
      name: product.name,
      price: product.price,
      imageUrl: "https://images.vartakt.com/images/avanti/merge/ea5d09fd-0dbc-42b5-85c6-b60c50d81702.jpg",
      selectedSize,
    };
    const updatedCart = [...cart, newCartItem];
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    setToastMessage(
      `${product.name} (Größe: ${selectedSize}) wurde zum Warenkorb hinzugefügt!`
    );
  };

  const handleShowCart = () => setShowCart(!showCart);
  const handleCloseCart = () => setShowCart(false);

  return (
    <div>
      {toastMessage && (
        <Toast message={toastMessage} onClose={() => setToastMessage(null)} />
      )}

      <div className="shopContainer">
        <button className="cartButton" onClick={handleShowCart}>
          🛒 Warenkorb
        </button>
        {showCart ? (
          <Cart cart={cart} onClose={handleCloseCart} setCart={setCart} />
        ) : isLoading ? (
          <p>Produkte werden geladen...</p>
        ) : (
          products.map((product) => (
            <ProductComponent
              key={product.id}
              product={product}
              handleAddToCart={handleAddToCart}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Shop;
