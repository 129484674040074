import React from "react";
import { useGlitch } from "react-powerglitch";
import styled from "styled-components";
import background from "../assets/background.jpg";
import logo from "../assets/logo.svg";
import Events from "./Events";
import GrooveCastIII from "./GooveCast/GrooveCastIII";
import Residents from "./Residents/Residents";
import Section from "../Section/Section";
import Footer from "../Footer";

const LandingPage: React.FC = () => {
  const glitch = useGlitch({
    playMode: "hover",
  });

  return (
    <LandingPageContainer>
      <GrooveHeadline>We bring the Groove</GrooveHeadline>
      <GrooveCastIII></GrooveCastIII>
      {/* <GrooveCastII></GrooveCastII> */}
      <EventsContainer>
        <Events></Events>
      </EventsContainer>
      <Section title="Residents" content={<Residents/>}/>
      <div>
      <Logo src={logo} alt="Music Label Logo" ref={glitch.ref} />
      </div>
      <Footer></Footer>
    </LandingPageContainer>
  );
};

export default LandingPage;

const GrooveHeadline = styled.h1`
  font-size: 4rem;
  margin-left: 1rem;
  color: white;
  font-family: "Ashing";
`;

const LandingPageContainer = styled.div`
  background-image: url(${background});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const EventsContainer = styled.div`
  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }
`;

const Logo = styled.img`
  width: 750px;
  height: auto;
  filter: drop-shadow(0 0 1rem purple);
  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    max-width: 80%; /* Smaller width on medium screens */
  }
`;
