import { Product } from "../domain/types";

export const fetchProductsStatic = async (): Promise<Product[]> => {
  const sizes = ["XS", "S", "M", "L"];
  const imageUrl =
    "https://images.vartakt.com/images/avanti/merge/ea5d09fd-0dbc-42b5-85c6-b60c50d81702.jpg";

  return [
    {
      name: "Avanti Pullover",
      sizes,
      price: 40,
      imageUrl,
      id: "1",
      description: ""
    },
    {
      name: "Avanti T-Shirt",
      sizes,
      price: 20,
      imageUrl,
      id: "2",
      description: ""
    },
  ];
};
