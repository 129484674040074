import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          &copy; 2023 Avanti Kollektiv. Alle Rechte vorbehalten.
        </p>
        <nav className="footer-links">
          <a href="#datenschutz">Datenschutz</a>
          <a href="#impressum">Impressum</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
