import React from "react";
import "./GrooveCastIII.css";
import SANDWICHO from "../../images/III/1.png";
import Marixx from "../../images/III/2.png";
import JUI from "../../images/III/3.png";

const grooveCastImages = [
  { src: SANDWICHO, link: "https://soundcloud.com/avantilabelgroup/set-sandwicho" },
  { src: Marixx, link: "https://soundcloud.com/avantilabelgroup/01-avanti-2" },
  { src: JUI, link: "#" }, // Falls JUI später noch eine SoundCloud-Seite bekommt
];

const GrooveCastIII: React.FC = () => {
  return (
    <div className="groove-cast-container">
      <h2 className="groove-title">Groove Cast Season III</h2>
      <div className="groove-scroll">
        {grooveCastImages.map((item, index) => (
          <div
            key={index}
            className="groove-item"
            onClick={() => window.open(item.link, "_blank")}
          >
            <img src={item.src} alt={`Groove ${index + 1}`} className="groove-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GrooveCastIII;
