import React, { useEffect, useState } from "react";
import "./Cart.css";
import { Address, CartItem } from "./domain/types";

interface CartProps {
  cart: CartItem[];
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
  onClose: () => void;
}

const Cart: React.FC<CartProps> = ({ cart, setCart, onClose }) => {
  const [address, setAddress] = useState<Address>({
    firstName: "",
    lastName: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
  });
  // const [paymentMessage, setPaymentMessage] = useState<string | null>(null);
  const [totalAmount, setTotalAmount] = useState(
    cart.reduce((total, item) => total + item.price, 0)
  ); // State für den Gesamtbetrag

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const newTotalAmount = cart.reduce((total, item) => total + item.price, 0);
    setTotalAmount(newTotalAmount);
  }, [cart]);

  const handleRemoveItem = (id: string) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleOrder = () => {
    const emailBody = `
      NEUE BESTELLUNG --- von ${address.firstName} ${address.lastName}
      
      **Kundendaten:**
      Name: ${address.firstName} ${address.lastName}
      Adresse: ${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}
      
      **Bestellte Artikel:**
      ${cart
        .map(
          (item) => `- ${item.name} (Größe: ${item.selectedSize}) - €${item.price.toFixed(2)}`
        )
        .join("\n")}
      
      **Gesamtbetrag:** €${totalAmount.toFixed(2)}
    `;

    const mailtoLink = `mailto:avantilabelgroup@gmail.com?subject=NEUE BESTELLUNG --- von ${address.firstName} ${address.lastName}&body=${encodeURIComponent(
      emailBody
    )}`;

    window.location.href = mailtoLink;
    setCart([]);
    localStorage.removeItem("cart");
    onClose();
  };

  // const handlePaymentSuccess = () => {
  //   alert("Zahlung erfolgreich abgeschlossen!");
  //   setCart([]);
  //   localStorage.removeItem("cart");
  //   onClose();
  // };

  return (
    <div className="cartContainer">
      <h2>Warenkorb</h2>
      <div className="cartItems">
        {cart.map((item) => (
          <div
            key={item.id}
            className="cartItem"
            onClick={() => handleRemoveItem(item.id)}
            onTouchStart={() => handleRemoveItem(item.id)}

          >
            <img
              src={item.imageUrl}
              alt={item.name}
              className="cartItemImage"
            />
            <div className="cartItemDetails">
              <p className="cartItemName">{item.name}</p>
              <p>Größe: {item.selectedSize}</p>
              <p>Preis: €{item.price.toFixed(2)}</p>
            </div>
          </div>
        ))}
      </div>
      <hr />
      <p className="totalAmount">Gesamtbetrag: €{totalAmount.toFixed(2)}</p>
      <h3>Adresse angeben</h3>
      <div className="addressForm">
        <input
          type="text"
          name="firstName"
          placeholder="Vorname"
          value={address.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Nachname"
          value={address.lastName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="street"
          placeholder="Straße"
          value={address.street}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="houseNumber"
          placeholder="Hausnummer"
          value={address.houseNumber}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="postalCode"
          placeholder="Postleitzahl"
          value={address.postalCode}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="city"
          placeholder="Ort"
          value={address.city}
          onChange={handleInputChange}
        />
      </div>
      {/* <div className="paypalContainer">
        <PayPalScriptProvider options={initialOptions}>
          <PayPalButtons
            style={{
              layout: "vertical",
              color: "gold",
              label: "pay",
              shape: "rect",
            }}
            createOrder={async () => {
              console.log(
                JSON.stringify({
                  cart,
                  totalAmount,
                  address,
                })
              );

              try {
                const response = await fetch(
                  "http://localhost:3000/api/create-order",
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                      cart,
                      totalAmount,
                      address,
                    }),
                  }
                );

                const data = await response.json();

                // Logge die komplette Antwort
                console.log("PayPal Create Order Response:", data);

                if (!response.ok || !data.orderId) {
                  throw new Error("Failed to create PayPal order");
                }

                // Logge die `orderId` direkt vor der Rückgabe
                console.log("Order ID:", data.orderId);

                return data.orderId; // Gib die Order ID zurück
              } catch (error) {
                console.error("Error creating PayPal order:", error);
                throw error;
              }
            }}
            onApprove={async (data) => {
              try {
                // const response = await fetch(
                //   `http://localhost:3000/api/capture-order/${data.orderID}`,
                //   {
                //     method: "POST",
                //   }
                // );
                // const details = await response.json();
                setPaymentMessage("Zahlung erfolgreich!");
                handlePaymentSuccess();
              } catch (error) {
                console.error("Fehler bei der Zahlung:", error);
                setPaymentMessage("Zahlung fehlgeschlagen.");
              }
            }}
            onError={(err) => {
              console.error("PayPal-Fehler:", err);
              setPaymentMessage("Ein Fehler ist aufgetreten.");
            }}
          />
        </PayPalScriptProvider> */}
        <button className="orderButton" onClick={handleOrder}>Bestellen</button>

      {/* </div> */}
      {/* {paymentMessage && <p>{paymentMessage}</p>} */}
      <button className="closeButton" onClick={onClose}>
        Zurück zum Shop
      </button>
    </div>
  );
};

export default Cart;
